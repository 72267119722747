<template>
    <div class="pb-3">
        <div class="card p-3">
            <div>
                <div class="row">
                    <div class="col-7 last-tipo">{{ call.tipo }}</div>
                    <div class="col-5 last-pass-text">Senha: <span class="last-pass">{{ call.senha }}</span></div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 last-position">{{ call.mesa }} / <span class="text-minor">{{ call.lado }}</span></div>
                <div
                    class="col-6 last-tipo-senha text-right"
                >
                    {{ call.tipoSenha.replaceAll(' ', '') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'last-call',

    props: ['call']
}
</script>

<style scoped>
.last-pass-text {
    font-size: 40px;
    font-weight: bold;
    text-align: right;
    color: dodgerblue;
    padding-right: 20px;
}
.last-pass {
    font-size: 50px;
    color: dodgerblue;
}
.last-tipo {
    font-size: 40px;
}
.last-position, .last-group, .last-tipo-senha {
    font-size: 35px;
}
.text-minor {
    font-size: 35px;
}
.last-tipo-senha {
    text-align: right;
}
</style>