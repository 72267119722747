<template>
    <div class="h-100">
        <div class="row h-75">
            <div class="col-6 p-5 text-center">
                <Call />
            </div>
            <div class="col-6 p-5">
                <LastCall
                    v-for="(call, index) in calls"
                    :key="index"
                    :call="call"
                />
            </div>
        </div>
        <div class="row h-25">
            <div class="row">
                <div class="col-2 p-3 text-center">
                    <div class="time-day">{{ day }}</div>
                    <div class="time-hour">{{ hour }}</div>
                </div>
                <div class="col-10 p-3">
                    <div class="card h-100 p-3">
                        <div class="row">
                            <div class="col-12">
                                <marquee>
                                    Seja bem vindo(a) ao Centro de Distrituição
                                    de Títulos e Documentos de São Paulo
                                </marquee>
                            </div>
                        </div>
                        <div class="row" v-if="false">
                            <div class="col-2">
                                <svg
                                    class="todaydata-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M416 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 128A96 96 0 1 0 416 0a96 96 0 1 0 0 192zM96 112c0-26.5 21.5-48 48-48s48 21.5 48 48V276.5c0 17.3 7.1 31.9 15.3 42.5C217.8 332.6 224 349.5 224 368c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-18.5 6.2-35.4 16.7-48.9C88.9 308.4 96 293.8 96 276.5V112zM144 0C82.1 0 32 50.2 32 112V276.5c0 .1-.1 .3-.2 .6c-.2 .6-.8 1.6-1.7 2.8C11.2 304.2 0 334.8 0 368c0 79.5 64.5 144 144 144s144-64.5 144-144c0-33.2-11.2-63.8-30.1-88.1c-.9-1.2-1.5-2.2-1.7-2.8c-.1-.3-.2-.5-.2-.6V112C256 50.2 205.9 0 144 0zm0 416c26.5 0 48-21.5 48-48c0-20.9-13.4-38.7-32-45.3V112c0-8.8-7.2-16-16-16s-16 7.2-16 16V322.7c-18.6 6.6-32 24.4-32 45.3c0 26.5 21.5 48 48 48z"
                                    />
                                </svg>
                                <span class="text-condition">{{ `${getWeather?.current?.feelslike_c} °C` }}</span>
                            </div>
                            <div class="col-4 text-condition">
                                <img :src="getWeather?.current?.condition?.icon" />
                                {{ getWeather?.current?.condition?.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1 p-3" v-if="false">
                    <div class="row">
                        <div class="col-12" style="text-align: right">
                            <svg
                                class="todaydata-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    d="M416 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 128A96 96 0 1 0 416 0a96 96 0 1 0 0 192zM96 112c0-26.5 21.5-48 48-48s48 21.5 48 48V276.5c0 17.3 7.1 31.9 15.3 42.5C217.8 332.6 224 349.5 224 368c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-18.5 6.2-35.4 16.7-48.9C88.9 308.4 96 293.8 96 276.5V112zM144 0C82.1 0 32 50.2 32 112V276.5c0 .1-.1 .3-.2 .6c-.2 .6-.8 1.6-1.7 2.8C11.2 304.2 0 334.8 0 368c0 79.5 64.5 144 144 144s144-64.5 144-144c0-33.2-11.2-63.8-30.1-88.1c-.9-1.2-1.5-2.2-1.7-2.8c-.1-.3-.2-.5-.2-.6V112C256 50.2 205.9 0 144 0zm0 416c26.5 0 48-21.5 48-48c0-20.9-13.4-38.7-32-45.3V112c0-8.8-7.2-16-16-16s-16 7.2-16 16V322.7c-18.6 6.6-32 24.4-32 45.3c0 26.5 21.5 48 48 48z"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-white text-weather">
                            {{ `${getWeather?.current?.feelslike_c} °C` }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-white text-condition">
                            <img :src="getWeather?.current?.condition?.icon" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-white text-condition">
                            {{ getWeather?.current?.condition?.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Call from "@/components/Call.vue";
import LastCall from "@/components/CardLastCall.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "painel-page",
    components: {
        Call,
        LastCall,
    },
    data() {
        return {
            hour: "hh:mm:ss",
            day: "dd/mm/yyyy",
            showCall: true,
        };
    },
    computed: {
        ...mapGetters(["getWeather"]),

        calls() {
            let result = [];
            let x = 5;
            let values = this.$store.getters.getCalls;

            for (var i = values.length - x; i <= values.length - 2; i++) {
                if (values[i] !== undefined) {
                    result.push(values[i]);
                }
            }

            return result.reverse();
        },
    },
    watch: {},
    methods: {
        addZero(i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        },
        updateTime() {
            this.hour = moment().format("HH:mm:ss");
            this.day = moment().format("DD/MM/YYYY");

            setTimeout(() => {
                this.updateTime();
            }, 1000);
        },
        newCall() {
            this.showCall = false;

            setTimeout(() => {
                this.showCall = true;
                this.playSound();
            }, 1000);
        },
        playSound() {
            let sound = "./assets/quito-mariscal-sucre.wav";

            var audio = new Audio(sound);
            audio.play().then(() => {
                audio = null;
            });
        },
    },
    created() {
        this.updateTime();
        console.log(this.calls);
    },
};
</script>

<style>
.time-day {
    color: white;
    font-size: 50px;
    margin-top: 50px;
}

.time-hour {
    color: white;
    font-size: 70px;
}

.call-type {
    color: white;
    font-size: 60px;
    font-weight: bold;
}

.call-pass {
    margin-top: -20px;
    font-size: 300px;
    color: dodgerblue;
    font-weight: bold;
    transition: color 6s ease;
}

.call-position {
    color: white;
    font-size: 70px;
    font-weight: bold;
}

.call-group {
    margin-top: -40px;
    color: white;
    font-size: 50px;
}

.call-side {
    font-size: 60px;
    color: white;
}

marquee {
    margin-top: 30px;
    font-size: 100px;
}

.slide-fade-enter-active {
    transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.todaydata-icon {
    width: 40px;
    fill: rgba(255, 255, 255, 0.781);
    margin-top: -10px;
    margin-right: 30px;
}
.text-weather {
    font-size: 40px;
}
.text-condition {
    font-size: 40px;
}
</style>