import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
        calls: [],
        weatherData: null
    },
    getters: {
        getCalls: state => state.calls,
        getSocket: state => state.socket,
        getWeather: state => state.weatherData
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
        },
        SOCKET_ONCLOSE(state) {
            state.socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message
            state.calls.push(message)
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
        SET_WEATHER(state, value) {
            state.weatherData = value
        }
    },
    actions: {
        sendMessage: function (context, message) {
            Vue.prototype.$socket.send(message)
        },
        async getWeatherData(/*{ commit }*/) {
            try {
                /*const response = await axios.get(
                  `${process.env.VUE_APP_BACKEND}/weather`
                )
        
                if (response.status === 200) {
                    commit('SET_WEATHER', response.data)
                }*/
            } catch (error) {
                console.error(error)
                alert('You have entered invalid or incorrect data/city name! Please try again.')
            }
        }
    }
})